import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import HandicapperDashboardLayer from "../components/HandicapperDashboardLayer";


const HandicapperDashboard = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb />

        {/* HandicapperDashboard */}
        <HandicapperDashboardLayer />


      </MasterLayout>
    </>
  );
};

export default HandicapperDashboard;
