import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import AddHotPickLayer from "../components/AddHotPickLayer";


const AddHotPickPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Hot Pick" />

        {/* CompanyLayer */}
        <AddHotPickLayer />


      </MasterLayout>
    </>
  );
};

export default AddHotPickPage;
