import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const RefundListLayer = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [status, setStatus] = useState({});

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const url = `${baseURL}/api/admin/refund-requests`;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setTransactions(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching refund requests:', error);
                setLoading(false);
            });
    }, [token, baseURL]);

    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const filteredTransactions = transactions.filter(transaction => {
        const matchesStatus = statusFilter === 'All' || transaction.status === statusFilter;
        const matchesSearchTerm =
            transaction.refundMethod.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesStatus && matchesSearchTerm;
    });

    const totalPages = Math.ceil(filteredTransactions.length / entriesPerPage);
    const startIndex = (currentPage - 1) * entriesPerPage;
    const currentTransactions = filteredTransactions.slice(startIndex, startIndex + entriesPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const updatePickStatus = async (refundId) => {
        try {
            const response = await axios.post(`${baseURL}/api/admin/update-refund-status`, {
                refundId,
                status: status[refundId]
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Refund status updated successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error updating pick status:', error.message);
            alert('Error updating refund status');
        }
    };

    const handleStatusChange = (pickId, newStatus) => {
        setStatus({ ...status, [pickId]: newStatus });
    };

    const renderSkeletonRows = () => (
        Array.from({ length: entriesPerPage }).map((_, index) => (
            <tr key={index}>
                <td><Skeleton width={20} /></td>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
            </tr>
        ))
    );

    return (
        <div className="card">
            <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <div className="d-flex align-items-center gap-2">
                        <span>Show</span>
                        <select
                            className="form-select form-select-sm w-auto"
                            defaultValue="10"
                            onChange={handleEntriesPerPageChange}
                            disabled={loading}
                        >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div className="icon-field">
                        <input
                            type="text"
                            name="search"
                            className="form-control form-control-sm w-auto"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            disabled={loading}
                        />
                        <span className="icon">
                            <Icon icon="ion:search-outline" />
                        </span>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <select className="form-select form-select-sm w-auto" value={statusFilter} onChange={handleStatusFilterChange}>
                        <option value="All">All</option>
                        <option value="Paid">Paid</option>
                        <option value="Pending">Pending</option>
                    </select>
                </div>
            </div>
            <div className="card-body">
                <table className="table bordered-table mb-0">
                    <thead>
                        <tr>
                            <th scope="col">S.L</th>
                            <th scope="col">User</th>
                            <th scope="col">Item</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Method</th>
                            <th scope="col">Details</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? renderSkeletonRows() : currentTransactions.map((refund, index) => (
                            <tr key={index}>
                                <td>{startIndex + index + 1}</td>
                                <td>{refund?.userId?.firstname} {refund?.userId?.lastname}</td>
                                <td>{refund?.transactionId?.pickId?.title || refund?.transactionId?.packageId?.name || refund?.transactionId?.subscriptionId?.name}</td>
                                <td>{refund?.amount}</td>
                                <td>{refund?.refundMethod}</td>
                                <td>
                                    {refund.refundMethod === 'paypal' ? (
                                        <div>Email: {refund.refundDetails?.paypalEmail}</div>
                                    ) : (
                                        <div>
                                            <div>Bank Name: {refund?.refundDetails?.bankName}</div>
                                            <div>Account Number: {refund?.refundDetails?.accountNumber}</div>
                                            <div>Account Title: {refund?.refundDetails?.accountTitle}</div>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {refund.status === 'accepted' ? (
                                        <span className="badge bg-success">Accepted</span>
                                    ) : refund.status === 'rejected' ? (
                                        <span className="badge bg-danger">Rejected</span>
                                    ) : refund.status === 'pending' ? (
                                        <div className="d-flex align-items-center gap-2 flex-column">
                                            <select
                                            className='form-select form-select-sm w-auto'
                                                value={status[refund._id] || refund.status}
                                                onChange={(e) => handleStatusChange(refund._id, e.target.value)}
                                            >
                                                <option value="pending" selected disabled>Select</option>
                                                <option value="accepted">Accept</option>
                                                <option value="rejected">Reject</option>
                                            </select>
                                            <button
                                                className='btn btn-primary btn-sm'
                                                onClick={() => updatePickStatus(refund._id)}>
                                                Update
                                            </button>
                                        </div>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
                    <span>
                        Showing {startIndex + 1} to {Math.min(startIndex + entriesPerPage, filteredTransactions.length)} of {filteredTransactions.length} entries
                    </span>
                    <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                        <li className="page-item">
                            <Link
                                className="page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px bg-base"
                                to="#"
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                <Icon icon="ep:d-arrow-left" className="text-xl" />
                            </Link>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                            <li key={index} className="page-item">
                                <Link
                                    className={`page-link ${index + 1 === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'} fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px`}
                                    to="#"
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Link>
                            </li>
                        ))}
                        <li className="page-item">
                            <Link
                                className="page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px bg-base"
                                to="#"
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                <Icon icon="ep:d-arrow-right" className="text-xl" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RefundListLayer;
