import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const HandicapperAddWithdrawalListLayer = () => {
    const [amount, setAmount] = useState('');
    const [method, setMethod] = useState('');
    const [account, setAccount] = useState('');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;

    const handleCancel = () => {
        navigate(-1);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            'amount': amount,
            'method': method,
            'account': account,
        }

        try {
            const response = await axios.post(`${baseURL}/api/handicapper/create-withdrawal`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.bill) {
                navigate('/handicapper/withdrawals');
            } else {
                alert('Failed to create package');
            }
        } catch (error) {
            console.error('Error creating pick:', error);
            alert('An error occurred while creating package');
        }
    };

    return (
        <div className="card h-100 p-0 radius-12 overflow-hidden">
            <div className="card-body p-40">
                <form onSubmit={handleFormSubmit} className='row'>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Amount <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="number"
                            className="form-control radius-8"
                            id="name"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter Amount"
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Payment Method <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                            placeholder="Enter Payment Method eg. Paypal, Bank Name etc."
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Account Number <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={account}
                            onChange={(e) => setAccount(e.target.value)}
                            placeholder="Enter Account Number"
                            required
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default HandicapperAddWithdrawalListLayer;
