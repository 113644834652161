import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import HandicappersLayer from "../components/HandicappersLayer";



const HandicappersPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Handicappers" />

        {/* HandicappersLayer */}
        <HandicappersLayer />

      </MasterLayout>

    </>
  );
};

export default HandicappersPage;
