import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePageThree from "./pages/HomePageThree";
import HandicapperDashboard from "./pages/HandicapperDashboard";
import CompanyPage from "./pages/CompanyPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ErrorPage from "./pages/ErrorPage";
import PicksPage from "./pages/PicksPage";
import HandicapperPicksPage from "./pages/HandicapperPicksPage";
import HandicappersPage from "./pages/HandicappersPage";
import TransactionListPage from "./pages/TransactionListPage";
import WithdrawalListPage from "./pages/WithdrawalListPage";
import UsersPage from "./pages/UsersPage";
import AddPickPage from "./pages/AddPickPage";
import HandicapperPackagePage from "./pages/HandicapperPackagePage";
import AddPackagePage from "./pages/AddPackagePage";
import HandicapperSubscriptionPage from "./pages/HandicapperSubscriptionPage";
import AddSubscriptionPage from "./pages/AddSubscriptionPage";
import HandicapperWithdrawalListPage from "./pages/HandicapperWithdrawalListPage";
import HandicapperAddWithdrawalListPage from "./pages/HandicapperAddWithdrawalListPage";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import PackagesPage from "./pages/PackagesPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import AddBannerPage from "./pages/AddBannerPage";
import NewsletterListPage from "./pages/NewsletterListPage";
import AddHotPick from "./pages/AddHotPick";
import HotPicksPage from "./pages/HotPicksPage";
import AddHotPickPage from "./pages/AddHotPickPage";
import HandicapperTransactionListPage from "./pages/HandicapperTransactionListPage";
import AddVideoLinkPage from "./pages/AddVideoLinkPage";
import PromotionBannersPage from "./pages/PromotionBannersPage";
import AddPromotionBannerPage from "./pages/AddPromotionBannerPage";
import RefundListPage from "./pages/RefundListPage";


function App() {
    return (
        <AuthProvider>
            <BrowserRouter basename="/">
                <Routes>
                    <Route exact path="/" element={<SignInPage />} />
                    <Route exact path="/signup" element={<SignUpPage />} />

                    {/* Admin Routes */}
                    <Route exact path="/dashboard" element={<ProtectedRoute element={HomePageThree} allowedRoles={['admin']} />} />
                    <Route exact path="/picks" element={<ProtectedRoute element={PicksPage} allowedRoles={['admin']} />} />
                    <Route exact path="/packages" element={<ProtectedRoute element={PackagesPage} allowedRoles={['admin']} />} />
                    <Route exact path="/subscriptions" element={<ProtectedRoute element={SubscriptionPage} allowedRoles={['admin']} />} />
                    <Route exact path="/transactions" element={<ProtectedRoute element={TransactionListPage} allowedRoles={['admin']} />} />
                    <Route exact path="/withdrawals" element={<ProtectedRoute element={WithdrawalListPage} allowedRoles={['admin']} />} />
                    <Route exact path="/settings" element={<ProtectedRoute element={CompanyPage} allowedRoles={['admin']} />} />
                    <Route exact path="/newsletter" element={<ProtectedRoute element={NewsletterListPage} allowedRoles={['admin']} />} />

                    <Route exact path="/handicappers" element={<ProtectedRoute element={HandicappersPage} allowedRoles={['admin']} />} />
                    
                    <Route exact path="/users" element={<ProtectedRoute element={UsersPage} allowedRoles={['admin']} />} />
                    <Route exact path="/add-banner" element={<ProtectedRoute element={AddBannerPage} allowedRoles={['admin']} />} />
                    <Route exact path="/update-video-links" element={<ProtectedRoute element={AddVideoLinkPage} allowedRoles={['admin']} />} />
                    <Route exact path="/hot-picks" element={<ProtectedRoute element={HotPicksPage} allowedRoles={['admin']} />} />
                    <Route exact path="/add-hot-pick" element={<ProtectedRoute element={AddHotPickPage} allowedRoles={['admin']} />} />
                    <Route exact path="/promotion-banners" element={<ProtectedRoute element={PromotionBannersPage} allowedRoles={['admin']} />} />
                    <Route exact path="/add-promotion-banner" element={<ProtectedRoute element={AddPromotionBannerPage} allowedRoles={['admin']} />} />
                    <Route exact path="/refund" element={<ProtectedRoute element={RefundListPage} allowedRoles={['admin']} />} />

                    
                    {/* Handiapper Routes */}
                    <Route exact path="/handicapper/dashboard" element={<ProtectedRoute element={HandicapperDashboard} allowedRoles={['handicapper']} />} />

                    <Route exact path="/handicapper/picks" element={<ProtectedRoute element={HandicapperPicksPage} allowedRoles={['handicapper']} />} />
                    <Route exact path="/handicapper/create-pick" element={<ProtectedRoute element={AddPickPage} allowedRoles={['handicapper']} />} />

                    <Route exact path="/handicapper/packages" element={<ProtectedRoute element={HandicapperPackagePage} allowedRoles={['handicapper']} />} />
                    <Route exact path="/handicapper/create-package" element={<ProtectedRoute element={AddPackagePage} allowedRoles={['handicapper']} />} />

                    <Route exact path="/handicapper/subscriptions" element={<ProtectedRoute element={HandicapperSubscriptionPage} allowedRoles={['handicapper']} />} />
                    <Route exact path="/handicapper/create-subscription" element={<ProtectedRoute element={AddSubscriptionPage} allowedRoles={['handicapper']} />} />
                    <Route exact path="/handicapper/transactions" element={<ProtectedRoute element={HandicapperTransactionListPage} allowedRoles={['handicapper']} />} />
                    <Route exact path="/handicapper/withdrawals" element={<ProtectedRoute element={HandicapperWithdrawalListPage} allowedRoles={['handicapper']} />} />
                    <Route exact path="/handicapper/request-withdrawal" element={<ProtectedRoute element={HandicapperAddWithdrawalListPage} allowedRoles={['handicapper']} />} />

                    <Route exact path="/handicapper/settings" element={<ProtectedRoute element={CompanyPage} allowedRoles={['handicapper']} />} />

                    <Route exact path="*" element={<ErrorPage />} />


                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;