import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddPromotionBannerLayer = () => {
    const token = localStorage.getItem('token');
    const [imagePreviewHome, setImagePreviewHome] = useState(null);
    const [matchData, setMatchData] = useState({
        banner: null,
    });
    const [handicapperId, setHandicapperId] = useState('');
    const fileInputRefHome = useRef(null);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [leagues, setLeagues] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/admin/handicappers`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.users) {
                    setLeagues(response?.data?.users || []);
                } else {
                    alert('Failed to fetch handicappers');
                }
            } catch (error) {
                console.error('Error fetching handicappers:', error);
                alert('An error occurred while fetching handicappers');
            }
        };

        fetchLeagues();
    }, [token]);

    // Handle file input for home team logo
    const handleFileChangeHome = (e) => {
        if (e.target.files.length) {
            const src = URL.createObjectURL(e.target.files[0]);
            setImagePreviewHome(src);
            setMatchData((prev) => ({ ...prev, banner: e.target.files[0] }));
        }
    };

    // Remove uploaded image for home team
    const removeImageHome = () => {
        setImagePreviewHome(null);
        if (fileInputRefHome.current) {
            fileInputRefHome.current.value = "";
        }
    };

    // Handle match entry form submission
    const handleMatchSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('handicapperId', handicapperId);
            if (matchData.banner) formData.append('banner', matchData.banner);

            const response = await axios.post(`${baseURL}/api/admin/add-promotion-banner`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                }
            });

            alert("Banner entry created successfully!");
            navigate('/promotion-banners');
        } catch (error) {
            console.error(error);
            alert("Error adding banner entry. Please try again later.");
        }
    };

    return (
        <div>
            <div className="card h-100 p-0 radius-12 overflow-hidden mb-3">
                <div className="card-body p-40">
                    <form onSubmit={handleMatchSubmit}>

                        {/* Banner */}
                        <div className="upload-image-wrapper d-flex align-items-center gap-3 mb-20">
                            {imagePreviewHome ? (
                                <div className="uploaded-img position-relative h-200-px w-200-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                                    <button
                                        type="button"
                                        onClick={removeImageHome}
                                        className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                                        aria-label="Remove uploaded image"
                                    >
                                        <Icon icon="radix-icons:cross-2" className="text-xl text-danger-600" />
                                    </button>
                                    <img className="w-100 h-100 object-fit-cover" src={imagePreviewHome} alt="Home Team Logo" />
                                </div>
                            ) : (
                                <label htmlFor="home_team_logo" className="upload-file h-200-px w-200-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1">
                                    <Icon icon="solar:camera-outline" className="text-xl text-secondary-light" />
                                    <span className="fw-semibold text-secondary-light">Upload Home Logo</span>
                                </label>
                            )}
                            <input
                                id="home_team_logo"
                                type="file"
                                onChange={handleFileChangeHome}
                                hidden
                                ref={fileInputRefHome}
                                accept="image/*"
                            />
                        </div>

                        <div className="mb-20 col-6">
                            <label
                                htmlFor="picks"
                                className="form-label fw-semibold text-primary-light text-sm mb-8"
                            >
                                Select Handicapper <span className="text-danger-600">*</span>
                            </label>
                            <br />
                            <select name="handicapperId" id="handicapperId" className="form-control radius-8" onChange={(e) => setHandicapperId(e.target.value)} required>
                                <option value="" selected disabled>Select handicapper</option>
                                {leagues.map((league) => (
                                    <option key={league._id} value={league._id}>
                                        {league.firstname} {league.lastname}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                            <button type="submit" className="btn btn-primary border border-primary-600 text-md px-24 py-12 radius-8">
                                Add Banner
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddPromotionBannerLayer;
