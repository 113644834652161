import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';

const AddBannerLayer = () => {
    const token = localStorage.getItem('token');
    const [imagePreviewHome, setImagePreviewHome] = useState(null);
    const [imagePreviewAway, setImagePreviewAway] = useState(null);
    const [matchData, setMatchData] = useState({
        home_team: '',
        away_team: '',
        home_team_logo: null,
        away_team_logo: null,
        game_time: '',
        show_date: ''
    });
    const fileInputRefHome = useRef(null);
    const fileInputRefAway = useRef(null);
    const baseURL = process.env.REACT_APP_BASE_URL;

    // Handle file input for home team logo
    const handleFileChangeHome = (e) => {
        if (e.target.files.length) {
            const src = URL.createObjectURL(e.target.files[0]);
            setImagePreviewHome(src);
            setMatchData((prev) => ({ ...prev, home_team_logo: e.target.files[0] }));
        }
    };

    // Handle file input for away team logo
    const handleFileChangeAway = (e) => {
        if (e.target.files.length) {
            const src = URL.createObjectURL(e.target.files[0]);
            setImagePreviewAway(src);
            setMatchData((prev) => ({ ...prev, away_team_logo: e.target.files[0] }));
        }
    };

    // Remove uploaded image for home team
    const removeImageHome = () => {
        setImagePreviewHome(null);
        if (fileInputRefHome.current) {
            fileInputRefHome.current.value = "";
        }
    };

    // Remove uploaded image for away team
    const removeImageAway = () => {
        setImagePreviewAway(null);
        if (fileInputRefAway.current) {
            fileInputRefAway.current.value = "";
        }
    };

    // Handle change in match data
    const handleMatchDataChange = (e) => {
        const { name, value } = e.target;
        setMatchData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle match entry form submission
    const handleMatchSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('home_team', matchData.home_team);
            formData.append('away_team', matchData.away_team);
            formData.append('game_time', matchData.game_time);
            formData.append('show_date', matchData.show_date);
            if (matchData.home_team_logo) formData.append('home_team_logo', matchData.home_team_logo);
            if (matchData.away_team_logo) formData.append('away_team_logo', matchData.away_team_logo);

            const response = await axios.post(`${baseURL}/api/admin/add-banner`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log(response);
            alert("Match entry created successfully!");
        } catch (error) {
            console.error(error);
            alert("Error creating match entry. Please try again later.");
        }
    };

    return (
        <div>
            <div className="card h-100 p-0 radius-12 overflow-hidden mb-3">
                <div className="card-body p-40">
                    <form onSubmit={handleMatchSubmit}>
                        {/* Home Team */}
                        <div className="mb-20">
                            <label htmlFor="home_team" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Home Team <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="home_team"
                                name="home_team"
                                placeholder="Enter Home Team Name"
                                value={matchData.home_team}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        {/* Home Team Logo */}
                        <div className="upload-image-wrapper d-flex align-items-center gap-3 mb-20">
                            {imagePreviewHome ? (
                                <div className="uploaded-img position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                                    <button
                                        type="button"
                                        onClick={removeImageHome}
                                        className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                                        aria-label="Remove uploaded image"
                                    >
                                        <Icon icon="radix-icons:cross-2" className="text-xl text-danger-600" />
                                    </button>
                                    <img className="w-100 h-100 object-fit-cover" src={imagePreviewHome} alt="Home Team Logo" />
                                </div>
                            ) : (
                                <label htmlFor="home_team_logo" className="upload-file h-200-px w-200-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1">
                                    <Icon icon="solar:camera-outline" className="text-xl text-secondary-light" />
                                    <span className="fw-semibold text-secondary-light">Upload Home Logo</span>
                                </label>
                            )}
                            <input
                                id="home_team_logo"
                                type="file"
                                onChange={handleFileChangeHome}
                                hidden
                                ref={fileInputRefHome}
                                accept="image/*"
                            />
                        </div>

                        {/* Away Team */}
                        <div className="mb-20">
                            <label htmlFor="away_team" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Away Team <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="away_team"
                                name="away_team"
                                placeholder="Enter Away Team Name"
                                value={matchData.away_team}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        {/* Away Team Logo */}
                        <div className="upload-image-wrapper d-flex align-items-center gap-3 mb-20">
                            {imagePreviewAway ? (
                                <div className="uploaded-img position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                                    <button
                                        type="button"
                                        onClick={removeImageAway}
                                        className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                                        aria-label="Remove uploaded image"
                                    >
                                        <Icon icon="radix-icons:cross-2" className="text-xl text-danger-600" />
                                    </button>
                                    <img className="w-100 h-100 object-fit-cover" src={imagePreviewAway} alt="Away Team Logo" />
                                </div>
                            ) : (
                                <label htmlFor="away_team_logo" className="upload-file h-200-px w-200-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1">
                                    <Icon icon="solar:camera-outline" className="text-xl text-secondary-light" />
                                    <span className="fw-semibold text-secondary-light">Upload Away Logo</span>
                                </label>
                            )}
                            <input
                                id="away_team_logo"
                                type="file"
                                onChange={handleFileChangeAway}
                                hidden
                                ref={fileInputRefAway}
                                accept="image/*"
                            />
                        </div>

                        {/* Match Time */}
                        <div className="mb-20">
                            <label htmlFor="game_time" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Game Time <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="datetime-local"
                                className="form-control radius-8"
                                id="game_time"
                                name="game_time"
                                value={matchData.game_time}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        {/* Match Time */}
                        <div className="mb-20">
                            <label htmlFor="show_date" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Banner Show Date <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="date"
                                className="form-control radius-8"
                                id="show_date"
                                name="show_date"
                                value={matchData.show_date}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                            <button type="submit" className="btn btn-primary border border-primary-600 text-md px-24 py-12 radius-8">
                                Add Game
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddBannerLayer;
