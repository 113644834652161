import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AddSubscriptionLayer = () => {
    const [selectedPicks, setSelectedPicks] = useState([]);
    const [price, setPrice] = useState('');
    const [duration, setDuration] = useState('');
    const [subscriptionTitle, setSubscriptionTitle] = useState('');
    const [leagues, setLeagues] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;

    const handleCancel = () => {
        navigate(-1);
    };

    const handlePickChange = (e) => {
        const pickId = e.target.value;
        if (e.target.checked) {
            setSelectedPicks((prev) => [...prev, pickId]);
        } else {
            setSelectedPicks((prev) => prev.filter((id) => id !== pickId));
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            'leagues': selectedPicks,
            'price': price,
            'durationInDays': duration,
            'name': subscriptionTitle,
        }
        console.log(payload)

        try {
            // Make a POST request to the backend API
            const response = await axios.post(`${baseURL}/api/handicapper/create-subscription`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.subscription) {
                navigate('/handicapper/subscriptions');
            } else {
                alert('Failed to create subscription');
            }
        } catch (error) {
            console.error('Error creating pick:', error);
            alert('An error occurred while creating subscription');
        }
    };

    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/handicapper/get-leagues`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.leagues) {
                    setLeagues(response.data.leagues || []);
                } else {
                    alert('Failed to fetch leagues');
                }
            } catch (error) {
                console.error('Error fetching leagues:', error);
                alert('An error occurred while fetching leagues');
            }
        };

        fetchLeagues();
    }, [token]);

    return (
        <div className="card h-100 p-0 radius-12 overflow-hidden">
            <div className="card-body p-40">
                <form onSubmit={handleFormSubmit} className='row'>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Subscription Title <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={subscriptionTitle}
                            onChange={(e) => setSubscriptionTitle(e.target.value)}
                            placeholder="Enter Subscription Title"
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Subscription Price <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter Subscription Title"
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Subscription Duration
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            placeholder="Enter Subscription Duration in days"
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="picks"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Select Leagues
                        </label>
                        <br />
                        {leagues.map((league) => (
                            <div key={league._id}>
                                <input
                                    type="checkbox"
                                    name="pickId"
                                    className="form-check-input radius-8"
                                    value={league._id}
                                    onChange={handlePickChange}
                                />{' '}
                                &nbsp;
                                {league?.title}
                            </div>
                        ))}
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSubscriptionLayer;
