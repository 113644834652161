import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import NewsletterListLayer from "../components/NewsletterListLayer";

const NewsletterListPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="News Letter Subscribers" />

        {/* NewsletterListLayer */}
        <NewsletterListLayer />

      </MasterLayout>

    </>
  );
};

export default NewsletterListPage;
