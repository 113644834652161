import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const HomePanner = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [error, setError] = useState(null);
    const [bannerToDelete, setBannerToDelete] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        axios.get(`${baseURL}/api/admin/get-banners`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                console.log('Banner:', response.data.banners);
                setBanners(response.data.banners || {});
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching banner:', error);
                setLoading(false);
                setError('Error fetching banner');
            });
    }, [token, baseURL]);

    const handleDeleteBanner = (id) => {
        setBannerToDelete(id);
        setDeleteModalVisible(true);
    };

    
    const confirmDeleteBanner = () => {
        console.log(bannerToDelete);
        if (bannerToDelete) {
            axios.delete(`${baseURL}/api/admin/delete-banner/${bannerToDelete}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(() => {
                    setBanners(banners.filter(banners => banners?._id !== bannerToDelete));
                    setDeleteModalVisible(false);
                })
                .catch((error) => {
                    console.error('Error deleting package:', error);
                    alert(error.response.data.error);
                    setDeleteModalVisible(false);
                });
        }
    };

    const cancelDelete = () => {
        setDeleteModalVisible(false);
        setBannerToDelete(null);
    };
    

    return (
        <div className="col-xxl-12 col-lg-12">
            {deleteModalVisible && (
                <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#00000066' }}>
                    <div className="modal-content" style={{ width: '500px', height: 'auto', padding: '10px' }}>
                        <div style={{ padding: '20px', textAlign: 'center' }}>
                            <b>Are you sure you want to delete this package?</b>
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <button
                                onClick={confirmDeleteBanner}
                                className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                            >
                                Delete
                            </button>
                            <button
                                onClick={cancelDelete}
                                className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="card h-100">
                <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        <h6 className="mb-2 fw-bold text-lg mb-0">Home Page Banner</h6>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        <Link to="/add-banner" className="btn btn-sm btn-primary-600">
                            <i className="ri-add-line" />Update Home Banner
                        </Link>
                    </div>
                </div>
                <div className="card-body p-24">
                    <div className="table-responsive scroll-sm">
                        <table className="table bordered-table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Home Team</th>
                                    <th scope="col">Away Team</th>
                                    <th scope="col">Banner Show Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading
                                    ? Array.from({ length: 1 }).map((_, index) => (
                                        <tr key={index}>
                                            <td><Skeleton width={150} /></td>
                                            <td><Skeleton width={150} /></td>
                                            <td><Skeleton width={150} /></td>
                                            <td><Skeleton width={150} /></td>
                                        </tr>
                                    ))
                                    : banners.map((banner, index) => (
                                        <tr key={index}>
                                            <td>
                                                <img
                                                    src={baseURL + banner?.home_team_logo}
                                                    alt={banner?.home_team}
                                                    className="flex-shrink-0 me-12 radius-8"
                                                    width={50}
                                                />
                                                {banner?.home_team}
                                            </td>
                                            <td>
                                                <img
                                                    src={baseURL + banner?.away_team_logo}
                                                    alt={banner?.away_team}
                                                    className="flex-shrink-0 me-12 radius-8"
                                                    width={50}
                                                />
                                                {banner?.away_team}
                                            </td>
                                            <td>
                                                {new Date(banner?.show_date).toLocaleDateString('en-US', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                })}
                                            </td>
                                            <td>
                                                <Link
                                                    to="#"
                                                    className="w-32-px h-32-px me-8 bg-danger-focus text-danger-main rounded-circle d-inline-flex align-items-center justify-content-center"
                                                    onClick={() => handleDeleteBanner(banner?._id)}
                                                >
                                                    <Icon icon="mingcute:delete-2-line" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePanner;
