import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const VideoLinks = () => {
    const [banner, setBanner] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [error, setError] = useState(null);

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        axios.get(`${baseURL}/api/admin/get-youtube-videos`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                setBanner(response.data.videos[0] || {});
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching banner:', error);
                setLoading(false);
                setError('Error fetching banner');
            });
    }, [token, baseURL]);

    return (
        <div className="col-xxl-12 col-lg-12">
            <div className="card h-100">
                <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        <h6 className="mb-2 fw-bold text-lg mb-0">Youtube Videos Links</h6>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        <Link to="/update-video-links" className="btn btn-sm btn-primary-600">
                            <i className="ri-add-line" />Update Video Links
                        </Link>
                    </div>
                </div>
                <div className="card-body p-24">
                    <div className="table-responsive scroll-sm">
                        <table className="table bordered-table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Link 1</th>
                                    <th scope="col">Link 2</th>
                                    <th scope="col">Link 3</th>
                                    <th scope="col">Link 4</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading
                                    ? Array.from({ length: 1 }).map((_, index) => (
                                        <tr key={index}>
                                            <td><Skeleton width={150} /></td>
                                            <td><Skeleton width={150} /></td>
                                            <td><Skeleton width={150} /></td>
                                        </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td>
                                                {banner?.link1}
                                            </td>
                                            <td>
                                                {banner?.link2}
                                            </td>
                                            <td>
                                                {banner?.link3}
                                            </td>
                                            <td>
                                                {banner?.link4}
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoLinks;
