import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import PackagesLayer from "../components/PackagesLayer";




const PackagesPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Packages" />

        {/* OrderListLayer */}
        <PackagesLayer />

      </MasterLayout>

    </>
  );
};

export default PackagesPage;
