import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AddPickLayer = () => {
    const [pickTitle, setPickTitle] = useState('');
    const [league, setLeague] = useState('');
    const [leagues, setLeagues] = useState([]);
    const [analysis, setAnalysis] = useState('');
    const [playType, setPlayType] = useState('');
    const [game, setGame] = useState('');
    const [games, setGames] = useState([]);
    const [bookmakers, setBookmakers] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [selectedOutcome, setSelectedOutcome] = useState(null);
    const baseURL = process.env.REACT_APP_BASE_URL;

    const handleCancel = () => {
        navigate(-1);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            'title': pickTitle,
            'league': league,
            'match': game,
            'bookmaker': selectedOutcome[0],
            'market': selectedOutcome[1],
            'outcome': selectedOutcome[2],
            'price': selectedOutcome[3],
            'point': selectedOutcome[4],
            'analysis': analysis,
            'playType': playType,
        }
        console.log(payload)

        try {
            // Make a POST request to the backend API
            const response = await axios.post(`${baseURL}/api/handicapper/create-pick`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.pick) {
                navigate('/handicapper/picks');
            } else {
                alert('Failed to create pick');
            }
        } catch (error) {
            console.error('Error creating pick:', error);
            alert('An error occurred while creating pick');
        }
    };

    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/handicapper/get-leagues`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.leagues) {
                    setLeagues(response.data.leagues || []);
                } else {
                    alert('Failed to fetch leagues');
                }
            } catch (error) {
                console.error('Error fetching leagues:', error);
                alert('An error occurred while fetching leagues');
            }
        };

        fetchLeagues();
    }, [token]);

    const handleLeagueChange = (e) => {
        setLeague(e.target.value);
        fetchGames(e.target.value);
    };

    const handleGameChange = (e) => {
        setGame(e.target.value);
        fetchBookmakers(e.target.value);
    };

    const fetchGames = async (leagueKey) => {
        try {
            const response = await axios.get(`${baseURL}/api/handicapper/get-games/${leagueKey}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data.games) {
                setGames(response.data.games || []);
            } else {
                alert('Failed to fetch games');
            }
        } catch (error) {
            console.error('Error fetching games:', error);
            alert('An error occurred while fetching games');
        }
    };

    const fetchBookmakers = async (leagueKey) => {
        try {
            const response = await axios.get(`${baseURL}/api/handicapper/get-bookmakers/${leagueKey}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data.game) {
                setBookmakers(response.data.game.bookmakers || []);
            } else {
                alert('Failed to fetch bookmakers');
            }
        } catch (error) {
            console.error('Error fetching bookmakers:', error);
            alert('An error occurred while fetching bookmakers');
        }
    };

    const groupGamesByDate = (games) => {
        const grouped = games.reduce((acc, gm) => {
            const commenceTime = new Date(gm.commenceTime);
            const formattedDate = new Intl.DateTimeFormat('en-US', {
                timeZone: 'America/New_York',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).format(commenceTime);
            console.log(formattedDate);
            if (!acc[formattedDate]) {
                acc[formattedDate] = [];
            }
            acc[formattedDate].push(gm);
            return acc;
        }, {});
    
        return grouped;
    };

    const groupedGames = groupGamesByDate(games);

    return (
        <div className="card h-100 p-0 radius-12 overflow-hidden">
            <div className="card-body p-40">
                <form onSubmit={handleFormSubmit} className='row'>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Pick Title <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={pickTitle}
                            onChange={(e) => setPickTitle(e.target.value)}
                            placeholder="Enter Pick Title"
                            required
                        />
                    </div>
                    <div className="mb-20 col-3">
                        <label
                            htmlFor="setPlayType"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Play Type
                            <span className="text-danger-600">*</span>{" "}
                        </label>
                        <select
                            className="form-control radius-8 form-select"
                            id="setPlayType"
                            value={playType}
                            onChange={(e) => setPlayType(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select Play Type</option>
                            <option value="Premium">Premium</option>
                            <option value="Free">Free</option>
                        </select>
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="game"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            League
                            <span className="text-danger-600">*</span>{" "}
                        </label>
                        <select
                            className="form-control radius-8"
                            id="league"
                            value={league}
                            onChange={handleLeagueChange}
                            required
                        >
                            <option value="">Select League</option>
                            {leagues.map((lg) => (
                                <option key={lg.key} value={lg.key}>
                                    {lg.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="game"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Game
                            <span className="text-danger-600">*</span>{" "}
                        </label>
                        <select
                            className="form-control radius-8"
                            id="game"
                            value={game}
                            onChange={handleGameChange}
                            required
                        >
                            <option value="" selected disabled>Select Game</option>
                            {Object.keys(groupedGames).map((date) => (
                                <optgroup label={date} key={date}>
                                    {groupedGames[date].map((gm) => {

                                        return (
                                            <option key={gm._id} value={gm._id}>
                                                {gm.homeTeam + ' -vs- ' + gm.awayTeam}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                            ))}
                        </select>
                    </div>
                    <table className="table bordered-table mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Bookmaker</th>
                                <th scope="col">Moneyline</th>
                                <th scope="col">Spread</th>
                                <th scope="col">Totals</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookmakers.map((bookmaker) => {
                                const moneyline = bookmaker.markets.find((market) => market.key === "h2h");
                                const spreads = bookmaker.markets.find((market) => market.key === "spreads");
                                const totals = bookmaker.markets.find((market) => market.key === "totals");

                                return (
                                    <tr key={bookmaker.title}>
                                        <td>{bookmaker.title}</td>

                                        {/* Moneyline Column */}
                                        <td style={{ padding: '0px' }}>
                                            {moneyline ? (
                                                moneyline.outcomes.map((outcome, index) => (
                                                    <div key={index} style={{ padding: '4px' }}>
                                                        <input
                                                            type="radio"
                                                            className="form-check-input radius-8 mt-8 mx-4"
                                                            id={outcome?.name}
                                                            name="outcome"
                                                            value={outcome?.name}
                                                            onChange={() =>
                                                                setSelectedOutcome([bookmaker._id, moneyline._id, outcome?.name, outcome?.price || '', outcome?.point || ''])
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={outcome?.name}
                                                            className="form-label fw-semibold text-primary-light text-sm mb-8 mt-4 mx-6"
                                                        >
                                                            {`${outcome?.price} (${outcome?.name})`}
                                                        </label>
                                                    </div>
                                                ))
                                            ) : (
                                                "null"
                                            )}
                                        </td>

                                        {/* Spread Column */}
                                        <td style={{ padding: '0px' }}>
                                            {spreads ? (
                                                spreads.outcomes.map((outcome, index) => (
                                                    <div key={index} style={{ padding: '4px' }}>
                                                        <input
                                                            type="radio"
                                                            className="form-check-input radius-8 mt-8 mx-4"
                                                            id={outcome?.name}
                                                            name="outcome"
                                                            value={outcome?.name}
                                                            onChange={() =>
                                                                setSelectedOutcome([bookmaker._id, spreads._id, outcome?.name, outcome?.price || '', outcome?.point || ''])
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={outcome?.name}
                                                            className="form-label fw-semibold text-primary-light text-sm mb-8 mt-4 mx-6"
                                                        >
                                                            {`${outcome?.point} / ${outcome?.price} (${outcome?.name})`}
                                                        </label>
                                                    </div>
                                                ))
                                            ) : (
                                                "null"
                                            )}
                                        </td>

                                        {/* Totals Column */}
                                        <td style={{ padding: '0px' }}>
                                            {totals ? (
                                                totals.outcomes.map((outcome, index) => (
                                                    <div key={index} style={{ padding: '4px' }}>
                                                        <input
                                                            type="radio"
                                                            className="form-check-input radius-8 mt-8 mx-4"
                                                            id={outcome?.name}
                                                            name="outcome"
                                                            value={outcome?.name}
                                                            onChange={() =>
                                                                setSelectedOutcome([bookmaker._id, totals._id, outcome?.name, outcome?.price || '', outcome?.point || ''])
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={outcome?.name}
                                                            className="form-label fw-semibold text-primary-light text-sm mb-8 mt-4 mx-6"
                                                        >
                                                            {`${outcome?.point} / ${outcome?.price} (${outcome?.name})`}
                                                        </label>
                                                    </div>
                                                ))
                                            ) : (
                                                "null"
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <div className="mb-20 col-12">
                        <label
                            htmlFor="analysis"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Analysis
                        </label>
                        <textarea
                            id="analysis"
                            rows="4"
                            value={analysis}
                            onChange={(e) => setAnalysis(e.target.value)}
                            className="form-control radius-8"
                            placeholder="Enter your analysis"
                        ></textarea>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddPickLayer;
