import React from 'react';
import RecentPicksOne from './child/RecentPicksOne'
import UnitCountOne from './child/UnitCountOne'
import HomePanner from './child/HomePanner';
import VideoLinks from './child/VideoLinks';

const DashBoardLayerThree = () => {
  
  return (
    <section className="row gy-4">

      <UnitCountOne />

      {/* RecentPicksOne */}
      <HomePanner />
      <VideoLinks />
      <RecentPicksOne />

    </section>


  )
}

export default DashBoardLayerThree