import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const AddVideoLinkLayer = () => {
    const token = localStorage.getItem('token');
    const [matchData, setMatchData] = useState({
        link1: '',
        link2: '',
        link3: '',
        link4: ''
    });
    const baseURL = process.env.REACT_APP_BASE_URL;

    // Handle change in match data
    const handleMatchDataChange = (e) => {
        const { name, value } = e.target;
        setMatchData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle match entry form submission
    const handleMatchSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${baseURL}/api/admin/youtube-videos`, matchData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log(response);
            alert("VIdeos links added successfully!");
        } catch (error) {
            console.error(error);
            alert("Error adding videos links. Please try again later.");
        }
    };

    return (
        <div>
            <div className="card h-100 p-0 radius-12 overflow-hidden mb-3">
                <div className="card-body p-40">
                    <form onSubmit={handleMatchSubmit}>
                        <div className="mb-20">
                            <label htmlFor="link1" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Link 1 <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="link1"
                                name="link1"
                                placeholder="Enter Link 1"
                                value={matchData.link1}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        <div className="mb-20">
                            <label htmlFor="link2" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Link 2 <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="link2"
                                name="link2"
                                placeholder="Enter Link 2"
                                value={matchData.link2}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        <div className="mb-20">
                            <label htmlFor="link3" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Link 3 <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="link3"
                                name="link3"
                                placeholder="Enter Link 3"
                                value={matchData.link3}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        <div className="mb-20">
                            <label htmlFor="link4" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Link 4 <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="link4"
                                name="link4"
                                placeholder="Enter Link 4"
                                value={matchData.link4}
                                onChange={handleMatchDataChange}
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                            <button type="submit" className="btn btn-primary border border-primary-600 text-md px-24 py-12 radius-8">
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddVideoLinkLayer;
