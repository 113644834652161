import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AddPackageLayer = () => {
    const [selectedPicks, setSelectedPicks] = useState([]);
    const [price, setPrice] = useState('');
    const [packageTitle, setPackageTitle] = useState('');
    const [leagues, setLeagues] = useState([]);
    const [guaranteed, setGuaranteed] = useState(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;

    const handleCancel = () => {
        navigate(-1);
    };

    const handlePickChange = (e) => {
        const pickId = e.target.value;
        if (e.target.checked) {
            setSelectedPicks((prev) => [...prev, pickId]);
        } else {
            setSelectedPicks((prev) => prev.filter((id) => id !== pickId));
        }
    };

    const handleGuaranteedChange = (e) => {
        setGuaranteed(e.target.checked);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            'picks': selectedPicks,
            'price': price,
            'name': packageTitle,
            'guaranteed': guaranteed,
        }
        console.log(payload)

        try {
            // Make a POST request to the backend API
            const response = await axios.post(`${baseURL}/api/handicapper/create-package`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.package) {
                navigate('/handicapper/packages');
            } else {
                alert('Failed to create package');
            }
        } catch (error) {
            console.error('Error creating pick:', error);
            alert('An error occurred while creating package');
        }
    };

    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/handicapper/picks`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.picks) {
                    setLeagues(response.data.picks || []);
                } else {
                    alert('Failed to fetch leagues');
                }
            } catch (error) {
                console.error('Error fetching leagues:', error);
                alert('An error occurred while fetching leagues');
            }
        };

        fetchLeagues();
    }, [token]);

    return (
        <div className="card h-100 p-0 radius-12 overflow-hidden">
            <div className="card-body p-40">
                <form onSubmit={handleFormSubmit} className='row'>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Package Title <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={packageTitle}
                            onChange={(e) => setPackageTitle(e.target.value)}
                            placeholder="Enter Package Title"
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="name"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Package Price <span className="text-danger-600">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control radius-8"
                            id="name"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter Package Title"
                            required
                        />
                    </div>
                    <div className="mb-20 col-6">
                        <label
                            htmlFor="picks"
                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                            Select Picks <span className="text-danger-600">*</span>
                        </label>
                        <br />
                        {leagues.map((league) => (
                            <div key={league.id}>
                                <input
                                    type="checkbox"
                                    name="pickId"
                                    className="form-check-input radius-8"
                                    value={league.id}
                                    onChange={handlePickChange}
                                />{' '}
                                &nbsp;
                                {league?.title}
                            </div>
                        ))}
                    </div>
                    <div>
                        <label>
                            Guaranteed: &nbsp;
                            <input
                                type="checkbox"
                                className="form-check-input radius-8"
                                checked={guaranteed}
                                onChange={handleGuaranteedChange}
                            />
                        </label>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddPackageLayer;
