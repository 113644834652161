import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import AddPromotionBannerLayer from "../components/AddPromotionBannerLayer";


const AddPromotionBannerPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Promotion Banner" />

        {/* CompanyLayer */}
        <AddPromotionBannerLayer />


      </MasterLayout>
    </>
  );
};

export default AddPromotionBannerPage;
