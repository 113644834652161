import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import RefundListLayer from "../components/RefundListLayer";




const RefundListPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Refund" />

        {/* OrderListLayer */}
        <RefundListLayer />

      </MasterLayout>

    </>
  );
};

export default RefundListPage;
