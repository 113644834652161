import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddHotPickLayer = () => {
    const token = localStorage.getItem('token');
    const [leagues, setLeagues] = useState([]);
    const [text, setText] = useState('');
    const [pickId, setPickId] = useState('');
    const [url, setUrl] = useState('');
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/admin/picks`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.picks) {
                    setLeagues(response.data.picks || []);
                } else {
                    alert('Failed to fetch picks');
                }
            } catch (error) {
                console.error('Error fetching picks:', error);
                alert('An error occurred while fetching picks');
            }
        };

        fetchLeagues();
    }, [token]);

    // Handle match entry form submission
    const handleMatchSubmit = async (e) => {
        e.preventDefault();

        try {
            const payload = {
                'text': text,
                'pickId': pickId,
                'url': url,
            };
            console.log(payload);
            const response = await axios.post(`${baseURL}/api/admin/add-hot-pick`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log(response);
            alert("Hot Pick added successfully!");
        } catch (error) {
            console.error(error);
            alert("Error adding hot pick. Please try again later.");
        }
    };

    return (
        <div>
            <div className="card h-100 p-0 radius-12 overflow-hidden mb-3">
                <div className="card-body p-40">
                    <form onSubmit={handleMatchSubmit}>
                        <div className="mb-20">
                            <label htmlFor="text" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Text <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="text"
                                name="text"
                                placeholder="Enter Hot Pick Text"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                        <div className="mb-20">
                            <label htmlFor="text" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                URL
                            </label>
                            <input
                                type="text"
                                className="form-control radius-8"
                                id="url"
                                name="url"
                                placeholder="Enter URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </div>
                        <div className="mb-20 col-6">
                            <label
                                htmlFor="picks"
                                className="form-label fw-semibold text-primary-light text-sm mb-8"
                            >
                                Select Pick
                            </label>
                            <br />
                            <select name="pickId" id="pickId" className="form-control radius-8" onChange={(e) => setPickId(e.target.value)}>
                                <option value="">Select a pick</option>
                                {leagues.map((league) => (
                                    <option key={league._id} value={league._id}>
                                        {league.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                            <button type="submit" className="btn btn-primary border border-primary-600 text-md px-24 py-12 radius-8">
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddHotPickLayer;
