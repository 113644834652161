import React from 'react';
import HandicapperRecentPicksOne from './child/HandicapperRecentPicksOne'
import HandicapperUnitCountOne from './child/HandicapperUnitCountOne'

const HandicapperDashboardLayer = () => {

  return (
    <section className="row gy-4">

      <HandicapperUnitCountOne />

      {/* HandicapperRecentPicksOne */}
      <HandicapperRecentPicksOne />

    </section>


  )
}

export default HandicapperDashboardLayer